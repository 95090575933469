import { useEffect } from "react";


const AccessDenied = () =>{
    
    useEffect(()=>{
        document.title="Access Denied"
    },[]);

    return <>
        <div>You don't have permission to view this page</div>
    </>
}

export default AccessDenied;