import React, { useState, useEffect } from 'react';
import Navbar from '../../navbar/Navbar';
import MaterialTable from 'material-table';
import axios from 'axios';
import AddExpense from '../../forms/expense/AddExpense';
import EditExpenseForm from '../../forms/expense/EditExpenseForm';
import ViewAttachment from '../../UI/ViewAttachment';
import styles from './ExpenseTableWithAView.module.css'

const ExpenseTableWithAView = () => {

    // page name**********************
    useEffect(() => {
        document.title = "Expenses"
    }, []);

    const [data, setData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [editData, setEditData] = useState();
    const [showEditModal, setEditShowModal] = useState(false);
    const [attachmentData, setAttachmentData] = useState("");
    const [attachmentName, setAttachmentName] = useState("");
    const [attachmentDataType, setAttachmentDataType] = useState("");
    const [showAttachment, setShowAttachment] = useState(false);

    // state for pending sort*************
    const [pendingSort, setPendingSort] = useState(false);

    // state for sorting date 
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    //show/hide expense modal***********************
    const [showModal, setShowModal] = useState(false);
    const showExpenseModal = () => {
        setShowModal(true);
    }
    const hideExpenseModal = () => {
        setShowModal(false);
    }

    // to fetch data from server*************************************
    const URL = "https://project-tracker-7df16-default-rtdb.asia-southeast1.firebasedatabase.app/expenses.json";
    const queryParam = `?auth=${localStorage.getItem("token")}`;
    let test = "";
    const fetchData = async () => {
        try {
            const response = await axios.get(URL + queryParam);
            if (response.status !== 200) {
                throw new Error("Something went wrong")
            }
            let data = [];
            //to get data from the Promise
            for (const key in response.data) {
                const dataObj = {
                    id: key,
                    ...response.data[key]
                }
                data.push(dataObj)
            }
            test = data;
            setData(data);
            setTableData(data);
            // setFilterData(data)

        } catch (error) {
            alert("Failed to fetch data", error)
        }
        setIsLoading(false)
    };
    useEffect(() => {
        setIsLoading(true);
        fetchData();
    }, []);

    // to filterdata as per status**********************
    useEffect(() => {
        if (pendingSort) {
            setTableData(data.filter(x => x["Status"] === "Pending"))
        } else {
            setTableData(data)
        }
    }, [pendingSort]);

    // to filter data as per the invoice date***********************************************
    useEffect(() => {
        if (startDate && !endDate) {
            setTableData(data.filter(x => x["Invoice Date"] >= startDate))
        }
        if (endDate && !startDate) {
            setTableData(data.filter(x => x["Invoice Date"] <= endDate))
        }
        if (!startDate && !endDate) {
            setTableData(data)
        }
        if (startDate && endDate) {
            setTableData(data.filter(x => x["Invoice Date"] >= startDate && x["Invoice Date"] <= endDate))
        }
    }, [startDate, endDate]);

    //to hide Edit Form****************************
    const hideEditExpenseModal = () => {
        setEditShowModal(false)
    }

    // to show/hide attachment ******************
    const hideAttachment = () => {
        setShowAttachment(false)
    }

    // date format *************
    function readableDate(date) {
        return date
            ? new Date(date).getDate() +
            " " +
            new Date(date).toLocaleString("default", { month: "long" }) +
            " " +
            new Date(date).getFullYear()
            : "";
    }

    //columns********************
    const columns = [
        {
            title: 'Expense Type',
            field: 'Expense Type'
        },
        {
            title: "Invoice Date",
            render: rowData => <span>{readableDate(rowData["Invoice Date"]).toLocaleString().split(",")[0]}</span>
        },
        {
            title: "Invoice Number",
            field: "Invoice Number",
        },
        {
            title: "Payee",
            field: "Payee",
        },
        {
            title: "Taxable",
            field: "Taxable",
        },
        {
            title: "SGST",
            field: "SGST",
        },
        {
            title: "CGST",
            field: "CGST",
        },
        {
            title: "IGST",
            field: "IGST",
        },
        {
            title: "Invoice Value",
            field: "Invoice Value",
        },
        {
            title: "GST Number",
            field: "GST Number",
        },
        {
            title: "Mode Of Payment",
            field: "Mode Of Payment",
        },
        {
            title: "Payor",
            field: "Payor",
        },
        {
            title: "Payment Date",
            render: rowData => <span>{readableDate(rowData["Payment Date"]).toLocaleString().split(",")[0]}</span>
        },
        {
            title: "Reimburse Date",
            render: rowData => <span>{readableDate(rowData["Reimburse Date"]).toLocaleString().split(",")[0]}</span>
        },
        {
            title: "Status",
            field: "Status",
        },
        {
            title: "Attachment",
            render: rowData => <span className={styles.attachmentButton} onClick={() => { setShowAttachment(true); setAttachmentDataType(rowData["File Type"]); setAttachmentData(rowData["File Data"]); setAttachmentName(rowData["File Name"]) }}>{rowData["File Name"]}</span>
        },
        {
            title: "Action",
            render: rowData => <div>
                <button onClick={() => { setEditData(rowData); setEditShowModal(true) }}>Edit</button>
            </div>
        }
    ]

    return (
        <>
            <Navbar />

            {/* Expense Button*************** */}
            <button style={{ marginTop: "1rem" }} onClick={showExpenseModal}>Add Expense</button>
            {showModal && <AddExpense onClose={hideExpenseModal} />}

            {/* to show Edit Expense Form****************************** */}
            {showEditModal && <EditExpenseForm onClose={hideEditExpenseModal} data={editData} />}

            {/* Sort Date************************************************************* */}
            {!isLoading && data.length !== 0 && <div className="date-input">
                <label className="date-range">
                    Start Date:
                    <input className="date-range" type="date" value={startDate} onChange={(e) => { setStartDate(e.target.value) }} />
                </label>

                <label className="date-range">
                    End Date:
                    <input className="date-range" type="date" value={endDate} onChange={(e) => { setEndDate(e.target.value) }} />
                </label>
            </div>}

            {/* sort by pending****************************************************** */}
            {!isLoading && data.length !== 0 && <div>
                <label>
                    Sort by Pending?
                    <input type="checkbox" checked={pendingSort} onChange={(e) => (setPendingSort(e.target.checked))} />
                </label>
            </div>}

            {/* to show attachments*************************** */}
            {showAttachment && <ViewAttachment name={attachmentName} type={attachmentDataType} data={attachmentData} onClose={hideAttachment} />}

            <MaterialTable title="Expenses" data={tableData} columns={columns} options={{
                headerStyle: {
                    backgroundColor: '#ff4a4a',
                    color: 'white',
                    border: "1px solid grey"
                },
                cellStyle: {
                    border: "1px solid grey"
                },
                exportButton: true,
                exportCsv: true,
            }} />
        </>
    )
}

export default ExpenseTableWithAView