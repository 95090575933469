import axios from "axios";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import Loading from "../../UI/Loading";
import Modal from "../../UI/Modal";
import styles from "./AddExpense.module.css";
import { toast } from "react-toastify";
import { UserContext } from '../../../store/user-context'

const AddExpense = (props) => {

    const { user } = useContext(UserContext)

    const URL = `https://project-tracker-7df16-default-rtdb.asia-southeast1.firebasedatabase.app/expenses.json?auth=${localStorage.getItem("token")}`;

    const [isLoading, setIsLoading] = useState(false);

    const [expenseType, setExpenseType] = useState("");
    const [invoiceDate, setInvoiceDate] = useState("");
    const [invoiceNumber, setInvoiceNumber] = useState("");
    const [inputName, setInputName] = useState("");
    const [taxable, setTaxable] = useState(0);
    const [igst, setIgst] = useState(0);
    const [cgst, setCgst] = useState(0);
    const [sgst, setSgst] = useState(0);
    const [invoiceValue, setInvoiceValue] = useState(0);
    const [gstNumber, setGstNumber] = useState("");
    const [modeOfPayment, setModeOfPayment] = useState("Bank Transfer");
    const [payor, setPayor] = useState("Vistaar");
    const [paymentDate, setPaymentDate] = useState("");
    const [reimburseDate, setReimburseDate] = useState("");
    const [fileName, setFileName] = useState("");
    const [fileData, setFileData] = useState("");
    const [fileDataType, setFileDataType] = useState("");
    const [status, setStatus] = useState("Pending");
    const [showReimburse, setShowReimburse] = useState(false);

    //to calculate SGST, CGST and IGST***************************************************
    useEffect(() => {
        if (gstNumber && (Number(String(gstNumber).slice(0, 2)) === 27)) {
            setCgst(taxable * 0.09);
            setSgst(taxable * 0.09);
            setIgst(0);
            setInvoiceValue(+taxable + (2 * (+taxable) * 0.09))
        }
        else if (gstNumber && !(Number(String(gstNumber).slice(0, 2)) === 27)) {
            setSgst(0);
            setCgst(0)
            setIgst(+taxable * 0.18);
            setInvoiceValue(+taxable + (+taxable * 0.18));
        }
        else {
            setInvoiceValue(+taxable)
        }
    }, [taxable, gstNumber]);

    //Upload file*****************
    const uploadFileHandler = e => {
        setFileName(e.target.files[0].name);
        setFileDataType(e.target.files[0].type);

        //Reading the file
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0])

        // reading the uploaded file. Converts the pdf/image file into base64 string.
        reader.onload = e => {
            setFileData(e.target.result);
        }
    }

    //updating status, reimbursement date and payment date as per payor***********
    useEffect(() => {
        if (payor === "Vistaar") {
            setShowReimburse(false);
            setStatus("Completed");
            setPaymentDate(invoiceDate);
            setReimburseDate("");
        } else {
            setShowReimburse(true);
            setStatus("Pending");
            setPaymentDate("");
        }
    }, [payor, invoiceDate]);

    //submitting data********************
    const formSubmitHandler = (e) => {
        e.preventDefault();

        setIsLoading(true);

        const data = {
            "Expense Type": expenseType,
            "Invoice Date": invoiceDate,
            "Invoice Number": invoiceNumber,
            Payee: inputName,
            Taxable: taxable,
            CGST: cgst,
            SGST: sgst,
            IGST: igst,
            "Invoice Value": invoiceValue,
            "GST Number": gstNumber,
            "Mode Of Payment": modeOfPayment,
            Payor: payor,
            "Payment Date": paymentDate,
            "Reimburse Date": reimburseDate,
            "File Name": fileName,
            "File Data": fileData,
            "File Type": fileDataType,
            Status: status,
            Name: user?.name,
        };

        axios
            .post(URL, data)
            .then((res) => {
                toast.success("Expense Added");
                props.onClose();
                setIsLoading(false);
                window.location.reload();
            })
            .catch((err) => {
                setIsLoading(false);
                toast.error("Failed to add expense", err);
            });
    };

    //Clearing form data*********************
    const clearFormHandler = (e) => {
        e.preventDefault();
        setExpenseType("");
        setInvoiceDate("");
        setInvoiceNumber("");
        setInputName("");
        setTaxable(0);
        setCgst(0);
        setSgst(0);
        setInvoiceValue("");
        setGstNumber("");
        setReimburseDate("");
        setFileData("");
    };

    return (
        <Modal onClose={props.onClose}>
            <strong>Add Expense</strong>

            <form className={styles.formElement} onSubmit={formSubmitHandler}>
                {/* Expense Type**************** */}
                <div>
                    <label>Expense Type:</label>
                    <select
                        required
                        value={expenseType}
                        onChange={(e) => {
                            setExpenseType(e.target.value);
                        }}
                    >
                        <option value="">---Select---</option>
                        <option>Amazon/Online</option>
                        <option>Asset</option>
                        <option>Cleaning</option>
                        <option>Electricity</option>
                        <option>Food</option>
                        <option>Fuel</option>
                        <option>Hosting</option>
                        <option>Internet</option>
                        <option>Misc</option>
                        <option>Mobile</option>
                        <option>QB</option>
                        <option>Stay</option>
                        <option>Tea</option>
                        <option>Travel</option>
                    </select>
                </div>

                {/* Invoice Date***************** */}
                <div>
                    <label>Invoice Date:</label>
                    <input
                        required
                        value={invoiceDate}
                        type="date"
                        onChange={(e) => setInvoiceDate(e.target.value)}
                    />
                </div>

                {/* Invoice Number***************** */}
                <div>
                    <label>Invoice Number:</label>
                    <input
                        required
                        value={invoiceNumber}
                        type="text"
                        onChange={(e) => setInvoiceNumber(e.target.value)}
                    />
                </div>

                {/* Name:***************** */}
                <div>
                    <label>Payee:</label>
                    <input
                        required
                        value={inputName}
                        type="text"
                        onChange={(e) => setInputName(e.target.value)}
                    />
                </div>

                {/* GST Number***************** */}
                <div>
                    <label>GST Number:</label>
                    <input
                        value={gstNumber}
                        type="text"
                        minlength="15"
                        maxlength="15"
                        onChange={(e) => setGstNumber(e.target.value)}
                    />
                </div>

                {/* Taxable:***************** */}
                <div>
                    <label>Taxable/Net Amount:</label>
                    <input
                        required
                        value={taxable}
                        type="number"
                        min="0"
                        onChange={(e) => setTaxable(e.target.value)}
                    />
                </div>

                {/* IGST:***************** */}
                {gstNumber && !(Number(String(gstNumber).slice(0, 2)) === 27) && (
                    <div>
                        <label>IGST:</label>
                        <input value={igst} type="number" min="0" readOnly />
                    </div>
                )}

                {/* SGST:***************** */}
                {gstNumber && Number(String(gstNumber).slice(0, 2)) === 27 && (
                    <div>
                        <label>SGST:</label>
                        <input value={sgst} type="number" min="0" readOnly />
                    </div>
                )}

                {/* CGST:***************** */}
                {gstNumber && Number(String(gstNumber).slice(0, 2)) === 27 && (
                    <div>
                        <label>CGST:</label>
                        <input value={cgst} type="number" min="0" readOnly />
                    </div>
                )}

                {/* Invoice Value:***************** */}
                <div>
                    <label>Invoice Value:</label>
                    <input value={invoiceValue} type="number" min="0" readOnly />
                </div>

                {/* Mode of Payment***************** */}
                <div>
                    <label>Mode of Payment:</label>
                    <select
                        required
                        value={modeOfPayment}
                        onChange={(e) => {
                            setModeOfPayment(e.target.value);
                        }}
                    >
                        <option>Bank Transfer</option>
                        <option>Cash</option>
                        <option>Credit Card</option>
                        <option>Expense Reimbursement</option>
                    </select>
                </div>

                {/* payee:***************** */}
                <div>
                    <label>Payor:</label>
                    <input
                        required
                        value={payor}
                        type="text"
                        onChange={(e) => setPayor(e.target.value)}
                    />
                </div>

                {/* Reimbursement Date:***************** */}
                {showReimburse && (
                    <div>
                        <label>Reimbursement Date:</label>
                        <input
                            required
                            value={reimburseDate}
                            type="date"
                            onChange={(e) => setReimburseDate(e.target.value)}
                        />
                    </div>
                )}

                {/* Attachment:***************** */}
                <div>
                    <label>Attachment:</label>
                    <input type="file" onChange={uploadFileHandler} />
                </div>

                {/* status:***************** */}
                <div>
                    <label>Status:</label>
                    <input type="text" value={status} readOnly />
                </div>

                <button disabled={isLoading} className={styles.actionBtn}>
                    {!isLoading && "Add"}
                    {isLoading && <Loading />}
                </button>
                <button className={styles.secondaryBtn} onClick={clearFormHandler}>
                    Clear
                </button>
                <button className={styles.secondaryBtn} onClick={props.onClose}>
                    Cancel
                </button>
            </form>
        </Modal>
    );
}

export default AddExpense;
