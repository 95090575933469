import Modal from "../../UI/Modal";
import { useCallback, useContext, useEffect, useState } from "react";
import { ailDivision, ascDivision, apcDivision } from "../../../data/divisionData";
import axios from "axios";
import LoadingSpinner from "../../UI/LoadingSpinner";
import { UserContext } from "../../../store/user-context";
import { toast } from "react-toastify";
import moment from "moment";


const EditTableData = (props) => {

    const { user, bu, buHandler } = useContext(UserContext)
    const [divisionArray, setDivisionArray] = useState(ailDivision);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    //states for the form data****************************
    const [division, setDivision] = useState(props.data["Division"]);
    const [receivedDate, setReceivedDate] = useState(props.data["Received Date"]);
    const [startDate, setStartDate] = useState(props.data["Start Date"]);
    const [activityType, setActivityType] = useState(props.data["Activity Type"]);
    const [finalDelivery, setFinalDelivery] = useState(props.data["Final Delivery"]);
    const [estimatedHours, setEstimatedHours] = useState(props.data["Estimated Hours"] || false);
    const [endDate, setEndDate] = useState(props.data["End Date"] || 0);
    const [va, setVa] = useState(props.data["Number of VAs"]);
    const [staticSlides, setStaticSlides] = useState(props.data["Static Slides"]);
    const [interactiveSlides, setInteractiveSlides] = useState(props.data["Interactive Slides"]);
    const [adaptionSlides, setAdaptionSlides] = useState(props.data["Adaption Slides"]);
    const [customPages, setCustomPages] = useState(props.data["Custom Pages"]);
    const [staticPages, setStaticPages] = useState(props.data["Static Pages"]);
    const [taskName, setTaskName] = useState(props.data["Task Name"]);
    const [emailerType, setEmailerType] = useState(props.data["Emailer Type"]);
    const [emailerLandingPages, setEmailerLandingPages] = useState(props.data["Number of Pages"]);
    const [emailerSubject, setEmailerSubject] = useState(props.data["Emailer Subject"]);
    const [addLink, setAddLink] = useState(props.data["Add Link"]);
    const [priority, setPriority] = useState(props.data["Priority"]);
    const [status, setStatus] = useState(props.data["Status"]);
    const [developers, setDevelopers] = useState([]);
    const [developerSlackId, setDeveloperSlackId] = useState(props.data["developerSlackId"] || "")
    const [vistaarDevelopers, setVistaarDevelopers] = useState(props.data["Vistaar Developers"]);
    const [buSpoc, setBuSpoc] = useState(props.data["BU SPOC"]);
    const [comments, setComments] = useState(props.data["Comments"]);

    // hiding input fields based on activity type***********************
    const activityTypeUpdate = (e) => {
        setActivityType(e.target.value);
    }

    const finalDateDeliveryHandler = (e) => {
        setFinalDelivery(e.target.value)
    }

    const statusHandler = (e) => {
        setStatus(e.target.value);
    }

    useEffect(() => {
        if (!(status === "Uploaded to Production")) {
            setFinalDelivery("")
        }
    }, [status]);

    // function to close the form using ESC button********************************
    useEffect(() => {
        const closeModal = (e) => {
            if (e.key === 'Escape') {
                props.onClose()
            }
        }
        window.addEventListener('keydown', closeModal);

        return () => window.removeEventListener('keydown', closeModal)
    }, [])


    const buUpdate = (e) => {
        buHandler(e.target.value);
    }

    useEffect(() => {
        switch (bu) {
            case "ail": return setDivision(ailDivision)

            case "asc": return setDivisionArray(ascDivision)

            case "apc": return setDivisionArray(apcDivision)

            default: return null
        }
    }, [bu]);

    //fetch developers data
    const developersData = () => {
        const token = localStorage.getItem('token')
        axios.get(`https://project-tracker-7df16-default-rtdb.asia-southeast1.firebasedatabase.app/developers.json?auth=${token}`)
            .then((res) => {
                let data = [];
                for (const key in res.data) {
                    data.push({ id: key, ...res.data[key] })
                }
                setDevelopers(data.sort((a, b) => a.name.localeCompare(b.name)))
            }).catch(() => {
                toast.error('Failed to get developers\' data')
            })
    }

    useEffect(() => {
        developersData()
    }, []);

    //end date calculator (ON HOLD)***************************8
    // const endDateHandler = (hours) => {

    //     let time = moment(`${endDate.split('T')[0]}T${endDate.split('T')[1]}`);

    //     if (estimatedHours > +hours) {
    //         time.add(+hours - estimatedHours, "h")
    //     } else {
    //         time.add(+hours - estimatedHours, "h")
    //     }

    //     console.log(time.format(), { endDate })

    //     //check if time exceeds 18:30******
    //     if (time.isAfter(moment().hour(18).minute(30))) {

    //         const end = time;
    //         const start = moment().hour(18).minute(30);
    //         //calculate difference
    //         const duration = moment.duration(end.diff(start)).asMinutes().toFixed(1);
    //         //calculate hours%24. This will be the days to add

    //         //add day and time
    //         setEndDate(moment(`${endDate.split('T')[0]}T09:30:00`).add(1, 'd').add(duration, 'minutes').format())
    //     }
    //     else if (time.isBefore(moment().hour(9).minute(30))) {
    //         console.log('BEFORE!!!')
    //         // setEndDate(moment(`${endDate.split('T')[0]}T09:30:00`).subtract(1, 'd').format())
    //     }
    //     else {
    //         setEndDate(time.format())
    //     }
    // }

    // useEffect(() => {
    //     // endDateHandler(estimatedHours);
    // }, [estimatedHours])

    const formSubmitHandler = useCallback((e) => {
        e.preventDefault();

        setIsLoading(true);

        const editedData = {
            "Division": division,
            "Received Date": receivedDate,
            "Start Date": startDate,
            "End Date": endDate,
            "Estimated Hours": estimatedHours,
            "Activity Type": activityType,
            "Number of VAs": va,
            "Static Slides": staticSlides,
            "Interactive Slides": interactiveSlides,
            "Adaption Slides": adaptionSlides,
            "Custom Pages": customPages,
            "Static Pages": staticPages,
            "Task Name": taskName,
            "Emailer Type": emailerType,
            "Number of Pages": emailerLandingPages,
            "Emailer Subject": emailerSubject,
            "Add Link": addLink,
            "Priority": priority,
            "Status": status,
            "Final Delivery": finalDelivery,
            "Vistaar SPOC": user?.name,
            "Vistaar Developers": vistaarDevelopers,
            developerSlackId: developerSlackId,
            "BU SPOC": buSpoc,
            "Comments": comments
        }

        const queryParam = '?auth=' + localStorage.getItem("token")

        // updating edited data in firebase database*******************************************
        axios.put(`https://project-tracker-7df16-default-rtdb.asia-southeast1.firebasedatabase.app/${bu}/${props.data.id}.json` + queryParam, editedData).then(() => {
            setIsLoading(false);
            setIsLoading(false);

            // to check if developer is changed. If developer is changed mid-taskName, update it on slack
            if (vistaarDevelopers !== props.data["Vistaar Developers"]) {

                const webhookUrl =
                    "https://hooks.slack.com/services/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";
                const data = {
                    text: `*Task Details:* \n
            Hello <@${vistaarDevelopers}> , \n
            *Activity Type:* ${activityType} \n
            *Business Unit:* ${bu.toUpperCase()} \n
            *Division:* ${division} \n
            *Task:* ${taskName} \n
            *ETA:* ${moment(endDate).format("DD-MM-yyyy,hh:mm:ss a")} \n
            ${va ? `*Number of VAs:* ${va}`.trim() : ``}
            ${emailerSubject ? `*Emailer Subject:* ${emailerSubject}\n` : ``}
            ${emailerSubject ? `*Links to add to emailer:* ${addLink}` : ``}
            \nFrom,\n ${user?.name}`
                };

                axios
                    .post(webhookUrl, JSON.stringify(data), {
                        withCredentials: false,
                        transformRequest: [
                            (data, headers) => {
                                delete headers.post["Content-Type"];
                                return data;
                            },
                        ],
                    })
                    .then(() => {
                        toast.success("Message Sent to Slack");
                    })
                    .catch((err) => {
                        toast.error("Failed to send the message");
                    });
            }

            //to send the date to history directory*********************************
            axios.post(`https://project-tracker-7df16-default-rtdb.asia-southeast1.firebasedatabase.app/history/${props.data.id}.json` + queryParam, {
                "Status": status,
                "Date": new Date().toISOString().slice(0, 10),
                "Developer": vistaarDevelopers
            }).then(() => {
                toast.success("Log updated!")
                window.location.reload();
            }).catch(() => {
                toast.error("Failed to update log!")
            })
            props.onClose();
        }).catch((err) => {
            setError(true)
            setIsLoading(false);

        })
    })


    // funtion to submit the form by pressing enter******************************
    useEffect(() => {
        const submitForm = (e) => {
            if (e.key === 'Enter') {
                formSubmitHandler()
            }
        }
        window.addEventListener('keydown', submitForm);

        return () => window.removeEventListener('keydown', submitForm)
    }, [formSubmitHandler])

    return <Modal onClose={props.onClose} >
        <div>
            {error && <p style={{ color: "red" }}>Something went wrong, please try updating the data again!</p>}
            {isLoading && <LoadingSpinner />}
            {!isLoading &&

                <form onSubmit={formSubmitHandler} >

                    {/* Status******************** */}
                    <label>
                        Status:
                        <select defaultValue={props.data["Status"]} onChange={statusHandler}>
                            <option value="">---Select---</option>
                            <option value="Not Initiated">Not Initiated</option>
                            <option value="In Progress">In Progress</option>
                            <option value="On Hold">On Hold</option>
                            <option value="Test Link Shared">Test Link Shared</option>
                            <option value="Uploaded to Sandbox">Uploaded to Sandbox</option>
                            <option value="Uploaded to Production">Uploaded to Production</option>
                            <option value="Feedback In Progress">Feedback In Progress</option>
                            <option value="Cancelled">Cancelled</option>
                        </select>
                    </label>

                    {/* Final date of Delivery**************** */}
                    <label>
                        Final Date of Delivery:
                        <input type="date" max={new Date().toISOString().slice(0, 10)} disabled={!(status === "Uploaded to Production")} required={status === "Uploaded to Production" || status === "Completed"} value={finalDelivery} onChange={finalDateDeliveryHandler}
                        />
                    </label>

                    {estimatedHours ? <>
                        <label htmlFor="estimatedTime">
                            Estimated Time to Complete (in hours)
                        </label><input
                            disabled
                            value={estimatedHours}
                            name="estimatedTime"
                            type="number"
                            min={1}
                            onChange={(e) => { setEstimatedHours(e.target.value) }} />

                        <div>
                            <p className="m-0">
                                ETA Date:
                            </p>
                            <div>
                                {moment(endDate).format("DD/MM/YYYY hh:mm A")}
                            </div>

                        </div>
                    </>
                        : null
                    }

                    {/* Comments******************** */}
                    <label>
                        Comments:
                        <input required={status === "On Hold" || status === "Cancelled"} defaultValue={props.data["Comments"]} type='text' onChange={(e) => { setComments(e.target.value) }} />
                    </label>

                    {/* BU************************ */}
                    <label>BU:
                        <select value={bu} required onChange={buUpdate}>
                            <option>
                                Select an Option
                            </option>
                            <option value="apc">APC</option>
                            <option value="asc">ASC</option>
                            <option value="ail">AIL</option>
                        </select>
                    </label>

                    {/* Division************************ */}

                    <label>Division:
                        <select defaultValue={props.data["Division"]} onChange={(e) => {
                            setDivision(e.target.value)
                        }}>
                            <option>{props.data["Division"]}</option>
                            {divisionArray.map((division, index) => (
                                <option value={division} key={index}>{division}</option>
                            ))}
                        </select>
                    </label>

                    {/* Activity type******************** */}
                    <label>
                        Activity Type:
                        <select defaultValue={props.data["Activity Type"]} onChange={activityTypeUpdate} >
                            <option value="EDetailing">EDetailing</option>
                            <option value="Emailer">Emailer</option>
                            <option value="Landing Page">Landing Page</option>
                            <option value="RMC">RMC</option>
                        </select>
                    </label>

                    {/* Number of VAs********************** */}
                    {!!props.data["Number of VAs"] && <label >
                        Number of VAs:
                        <input defaultValue={props.data["Number of VAs"]} type='number' min='1' required onChange={(e) => { setVa(e.target.value) }} />
                    </label>}

                    {/* Static Slides********************** */}
                    {!!props.data["Number of VAs"] && <label >
                        Static Slides:
                        <input defaultValue={props.data["Static Slides"]} type='number' min='0' onChange={(e) => { setStaticSlides(e.target.value) }} />
                    </label>}

                    {/* Interactive Slides********************** */}
                    {!!props.data["Number of VAs"] && <label >
                        Interactive Slides:
                        <input defaultValue={props.data["Interactive Slides"]} type='number' min='0' onChange={(e) => { setInteractiveSlides(e.target.value) }} />
                    </label>}

                    {/* Adaptation Slides********************** */}
                    {!!props.data["Number of VAs"] && <label >
                        Adaptation Slides:
                        <input defaultValue={props.data["Adaption Slides"]} type='number' min='0' onChange={(e) => { setAdaptionSlides(e.target.value) }} />
                    </label>}

                    {/* Emailer************************************************************* */}

                    {/* Emailer Type Pages********************** */}
                    {!!props.data["Emailer Type"] && <label>
                        Emailer Type:
                        <select value={props.data["Emailer Type"]} onChange={(e) => { setEmailerType(e.target.value) }}>
                            <option>Static</option>
                            <option>Custom</option>
                        </select>
                    </label>}

                    {/* Task/Emailer Name********************* */}
                    {<label>
                        Task/Emailer Name:
                        <input defaultValue={props.data["Task Name"]} type="text" onChange={(e) => { setTaskName(e.target.value) }} />
                    </label>}

                    {/* Emailer Subject********************* */}
                    {!!props.data["Emailer Type"] && <label>
                        Emailer Subject:
                        <input defaultValue={props.data["Emailer Subject"]} type="text" onChange={(e) => { setEmailerSubject(e.target.value) }} />
                    </label>}

                    {/* Add Link********************* */}
                    {!!props.data["Emailer Type"] && <label>
                        Add Link:
                        <input defaultValue={props.data["Add Link"]} type="text" onChange={(e) => { setAddLink(e.target.value) }} />
                    </label>}

                    {/* Number of Pages********************* */}
                    {!!props.data["Emailer Type"] && <label>
                        Number of Pages:
                        <input value={props.data["Number of Pages"] || 0} type="number" min="1" onChange={(e) => { setEmailerLandingPages(e.target.value) }} />
                    </label>}

                    {/* Priority******************** */}
                    <label>
                        Priority:
                        <select defaultValue={props.data["Priority"]} onChange={(e) => { setPriority(e.target.value) }}>
                            <option>Regular</option>
                            <option>High</option>
                        </select>
                    </label>

                    {/* Vistaar Developers ************************ */}
                    <label>
                        Vistaar Developer:
                        <select value={vistaarDevelopers} onChange={(e) => { setVistaarDevelopers(e.target.value) }}>
                            {developers.map(developer => (
                                <option key={developer.id}>{developer.name}</option>
                            ))}
                        </select>
                    </label>

                    {/* BU SPOC******************** */}
                    <label>
                        BU SPOC:
                        <input defaultValue={props.data["BU SPOC"]} type='text' onChange={(e) => { setBuSpoc(e.target.value) }} />
                    </label>

                    {/* Vistaar SPOC******************** */}
                    <label>
                        Vistaar SPOC:
                        <input value={props.data["Vistaar SPOC"]} type='text' onChange={(e) => { setBuSpoc(e.target.value) }} />
                    </label>

                    <button>Save</button>
                    &nbsp;
                    <button onClick={props.onClose}>Cancel</button>
                </form>
            }
        </div>
    </Modal>
}

export default EditTableData