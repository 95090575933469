import axios from 'axios';
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify';

const DevelopersModal = ({ show, hide, data: developers }) => {

    const [showModal, setShowModal] = useState(show);
    const [addDeveloper, setAddDeveloper] = useState(false);
    const [removeDeveloper, setRemoveDeveloper] = useState(false);
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [developerSlackId, setDeveloperSlackId] = useState("")

    const hideModalHandler = () => {
        setShowModal(false)
        hide()
    }

    const addDeveloperHandler = (e) => {
        e.preventDefault();

        let emailRegex = /^[a-z]+\.[a-z]+@vistaarmedia\.com$/;
        if (!emailRegex.test(email)) {
            return toast.error('Invalid email')
        }
        const token = localStorage.getItem('token')
        axios.post(`https://project-tracker-7df16-default-rtdb.asia-southeast1.firebasedatabase.app/developers.json?auth=${token}`, {
            email,
            name,
            developerSlackId
        }).then((res) => {
            toast.success('Developer added')
            setName("")
            setEmail("")
        }).catch((err) => {
            toast.error("Failed to add developer")
        })
    }

    const removeDeveloperHandler = (id) => {
        const token = localStorage.getItem('token')
        axios.delete(`https://project-tracker-7df16-default-rtdb.asia-southeast1.firebasedatabase.app/developers/${id}.json?auth=${token}`)
            .then((res) => {
                toast.success("Developer removed")
            }).catch((err) => {
                toast.error("Failed to remove developer")
            })
    }

    return (
        <Modal className='modal-dialog-scrollable' show={showModal} onHide={hideModalHandler}>
            <Modal.Header closeButton>
                <Modal.Title>Developers</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!addDeveloper && <button onClick={() => { setAddDeveloper(true) }} className='action-button mb-2'>Add</button>}
                {addDeveloper && <div>
                    <form onSubmit={addDeveloperHandler}>
                        <input placeholder='Enter Name' type="text" onChange={(e) => { setName(e.target.value) }} />
                        <input placeholder='Enter Email' type="email" onChange={(e) => { setEmail(e.target.value) }} />
                        <input placeholder='Enter Slack Id' type="text" onChange={(e) => { setDeveloperSlackId(e.target.value) }} />
                        <button type="submit" className='action-button mr-2'>Add</button>
                        <button onClick={() => { setAddDeveloper(false) }}>Cancel</button>
                    </form>
                </div>}
                <ul className='list-group'>
                    {developers.map(developer => (
                        <>
                            <li className='list-group-item' key={developer.id}>{developer.name}<i onClick={() => { setRemoveDeveloper(true) }} className="bi bi-dash-circle float-end" style={{ color: 'red' }}></i></li>
                            {removeDeveloper && <div className='mb-2 border'>
                                <p>{`Are you sure you want to remove ${developer.name}?`}</p>
                                <button onClick={() => { removeDeveloperHandler(developer.id) }} className='btn action-button'>Yes</button>
                                <button className='ms-2 btn btn-outline-secondary' onClick={() => { setRemoveDeveloper(false) }}>No</button>
                            </div>}
                        </>
                    ))}
                </ul>
            </Modal.Body>
        </Modal>
    )
}

export default DevelopersModal