import "./Login.css";
import { useState, useEffect, useContext } from "react";
import Loading from "../UI/Loading";
import { UserContext } from "../../store/user-context";

function Login() {

  //setting page title*********************************************************
  useEffect(() => {
    document.title = "Login";
  }, []);

  const { error, loginHandler } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const submitHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    loginHandler(email, password);
    setIsLoading(false)
  }

  return (
    <div className="container">
      {error && (
        <span style={{ color: "red", marginBottom: "2rem" }}>
          You entered wrong email/password. Please try again with correct
          credentials
        </span>
      )}
      <form className="loginContainer" onSubmit={submitHandler}>
        <h1>Login</h1>

        <label className="authLabel" htmlFor="email">
          Email:
        </label>
        <input
          className="authInput"
          type="email"
          name="email"
          onChange={(e) => { setEmail(e.target.value) }}
          required
        />

        <label className="authLabel" htmlFor="password">
          Password:
        </label>
        <input
          className="authInput"
          type="password"
          name="password"
          onChange={(e) => { setPassword(e.target.value) }}
          required
        />

        <button className="btnSubmit" type="submit">
          {!isLoading && "Login"}
          {isLoading && <Loading />}
        </button>
      </form>
    </div>
  );
}

export default Login;