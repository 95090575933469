import styles from "./Navbar.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../store/user-context";
import SuperAdminContent from '../../routes/super-admin/SuperAdminContent'

function Navbar() {

  const { user } = useContext(UserContext);
  const nav = useNavigate();

  // logout function*********************************
  const handleLogout = () => {
    localStorage.clear();
    nav("/");
  };

  return (
    <div>
      <nav className={`navbar navbar-expand-sm mb-3 ${styles.background}`}>
        {/* hamburger******* */}
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse">
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* hamburger content list************* */}
        <div id="navbarCollapse" className="collapse navbar-collapse">
          <ul className="navbar-nav w-100">
            <li className={`nav-item`}>
              <NavLink to="/calendar" activeclassname={styles.active} className={`nav-link ${styles.navitem}`}>
                Calendar
              </NavLink>
            </li>
            <li className={`nav-item`}>
              <NavLink to="/table-view" activeclassname={styles.active} className={`nav-link ${styles.navitem}`}>
                Table
              </NavLink>
            </li>
            <li className={`nav-item`}>
              <NavLink to="/add-task" activeclassname={styles.active} className={`nav-link ${styles.navitem}`}>
                Add Task
              </NavLink>
            </li>

            {/* super admin only*********** */}
            <SuperAdminContent>
              <li className={`nav-item`}>
                {<NavLink to="/expenses" activeclassname={styles.active} className={`nav-link ${styles.navitem}`}>
                  Expenses
                </NavLink>}
              </li>
            </SuperAdminContent>

            {/* logout************ */}
            <div className={`nav-link ${styles.navitem} ms-auto`} onClick={handleLogout}>
              Logout, {user?.name}
            </div>
          </ul>
        </div>
      </nav>
    </div >
  );
}

export default Navbar;
