import "./TaskForm.css";
import "./AddTaskForm.css";
import axios from "axios";
import { useState, useEffect, useContext } from "react";
import React, { useRef } from "react";
import Navbar from "../../navbar/Navbar";
import Loading from "../../UI/Loading";
import AddTaskOrNot from "../AddTaskOrNot";
import DevelopersModal from "./DevelopersModal";
import ProtectedContent from "../../../routes/admin/AdminOnlyContent";
import { UserContext } from "../../../store/user-context";
import { ailDivision, ascDivision, apcDivision } from '../../../data/divisionData';
import { toast } from "react-toastify";
import moment from "moment/moment";

function AddTaskForm() {

  //setting page title*********************************************************
  useEffect(() => {
    document.title = "Add Task";
  }, []);

  //fetch developers data
  const developersData = () => {
    const token = localStorage.getItem('token')
    axios.get(`https://project-tracker-7df16-default-rtdb.asia-southeast1.firebasedatabase.app/developers.json?auth=${token}`)
      .then((res) => {
        let data = [];
        for (const key in res.data) {
          data.push({ id: key, ...res.data[key] })
        }
        setDevelopers(data.sort((a, b) => a.name.localeCompare(b.name)))
      }).catch((err) => {
        toast.error('Failed to get developers\' data')
      })
  }

  const { user, email, bu, buHandler } = useContext(UserContext);

  const [division, setDivision] = useState();
  const [divisionArray, setDivisionArray] = useState([]);
  const [activityType, setActivityType] = useState("");
  const [staticSlides, setStaticSlides] = useState(0);
  const [vaHide, setVaHide] = useState(true);
  const [staticHide, setStaticHide] = useState(true);
  const [interactiveSlides, setInteractiveSlides] = useState(0);
  const [interactiveHide, setInteractiveHide] = useState(true);
  const [adaptionSlides, setAdaptionSlides] = useState(0);
  const [adaptionHide, setAdaptionHide] = useState(true);
  const [estimatedHours, setEstimatedHours] = useState(0);
  const [receivedTime, setReceivedTime] = useState(moment(new Date().toISOString()).format("HH:mm"));
  const [receivedDate, setReceivedDate] = useState(new Date().toISOString().split("T")[0]);
  const [startTime, setStartTime] = useState(moment(new Date().toISOString()).format("HH:mm"));
  const [startDate, setStartDate] = useState(new Date().toISOString().split("T")[0]);
  const [endDate, setEndDate] = useState();
  const [customPages, setCustomPages] = useState(0);
  const [customHide, setCustomHide] = useState(true);
  const [va, setVa] = useState(0);
  const [taskName, setTaskName] = useState("");
  const [emailerHide, setEmailerHide] = useState(true);
  const [nameSwitch, setNameSwitch] = useState();
  const [emailerType, setEmailerType] = useState();
  const [emailerLandingPages, setEmailerLandingPages] = useState(1);
  const [emailerSubject, setEmailerSubject] = useState();
  const [addLink, setAddLink] = useState("");
  const [priority, setPriority] = useState("Regular");
  const [status, setStatus] = useState("Not Initiated");
  const [vistaarSpoc, setVistaarSpoc] = useState(user?.name);
  const [developers, setDevelopers] = useState([])
  const [vistaarDevelopers, setvistaarDevelopers] = useState("");
  const [developerSlackId, setDeveloperSlackId] = useState("");
  const [developersEmail, setDevelopersEmail] = useState("");
  const [buSpoc, setBuSpoc] = useState();
  const [buSpocEmail, setBuSpocEmail] = useState("");
  const [comments, setComments] = useState();
  const [isInProgress, setIsInProgress] = useState(false);
  const [statusArray, setStatusArray] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const form = useRef();

  const [showModal, setShowModal] = useState(false);

  const [showDevelopersModal, setShowDevelopersModal] = useState(false);

  useEffect(() => {
    developersData()
  }, [])

  // set division array***************
  useEffect(() => {
    switch (bu) {
      case "ail": return setDivisionArray(ailDivision);

      case "apc": return setDivisionArray(apcDivision);

      case "asc": return setDivisionArray(ascDivision);

      default: return null
    }
  }, [bu])

  // to set brand name *******************************************************
  const divisionUpdate = (e) => {
    setDivision(e.target.value);
    statusArray.forEach((status) => {
      if (status === "In Progress") setIsInProgress(true);
    });
  };

  //to close the AddTaskOrNot modal***********************************************
  const hideModalHandler = () => {
    setShowModal(false);
  };

  // to set up activity type***********************************
  const activityTypeUpdate = (e) => {
    setActivityType(e.target.value);
    if (e.target.value === "Edetailing") {
      setVaHide(false);
      setStaticHide(false);
      setInteractiveHide(false);
      setAdaptionHide(false);
      setCustomHide(true);
      setEmailerHide(true);
      setNameSwitch("Task");
    } else if (e.target.value === "Emailer") {
      setVaHide(true);
      setStaticHide(true);
      setInteractiveHide(true);
      setAdaptionHide(true);
      setCustomHide(false);
      setEmailerHide(false);
      setNameSwitch("Emailer");
    } else if (e.target.value === "Landing Page") {
      setVaHide(true);
      setStaticHide(true);
      setInteractiveHide(true);
      setAdaptionHide(true);
      setCustomHide(false);
      setEmailerHide(false);
      setNameSwitch("Landing Page");
    } else if (e.target.value === "RMC") {
      setVaHide(true);
      setStaticHide(false);
      setInteractiveHide(false);
      setAdaptionHide(false);
      setCustomHide(true);
      setEmailerHide(true);
      setNameSwitch("Task Name");
    }
  };

  const priorityUpdate = (e) => {
    setPriority(e.target.value);
  };

  const statusUpdate = (e) => {
    setStatus(e.target.value);
  };

  // to set-up VA Update***********************************
  const vaUpdate = (e) => {
    setVa(e.target.value);
  };

  const staticSlidesUpdate = (e) => {
    setStaticSlides(e.target.value);
  };

  const interactiveSlidesUpdate = (e) => {
    setInteractiveSlides(e.target.value);
  };

  const adaptionSlidesUpdate = (e) => {
    setAdaptionSlides(e.target.value);
  };

  const taskNameUpdate = (e) => {
    setTaskName(e.target.value);
  };

  const emailerSubjectUpdate = (e) => {
    setEmailerSubject(e.target.value);
  };

  const addLinkUpdate = (e) => {
    setAddLink(e.target.value);
  };

  const vistaarDevelopersUpdate = (e) => {
    setvistaarDevelopers(e.target.value);
    setDevelopersEmail(developers.find(developer => e.target.value === developer.name).email)
    setDeveloperSlackId(developers.find(developer => e.target.value === developer.name).slackId)
  };

  const buSpocUpdate = (e) => {
    setBuSpoc(e.target.value);
  };

  const commentsUpdate = (e) => {
    setComments(e.target.value);
  };

  //developers modal************
  const hideDeveloperModalHandler = () => {
    setShowDevelopersModal(false)
  }

  //end date calculator
  const endDateHandler = (hours) => {
    if (!receivedDate || !receivedTime) {
      return toast.error('Please enter received date and time')
    }

    let time = moment(`${startDate}T${startTime}:00`);

    // calc pending hours which will be the difference between received time and 18:30
    // if pending hour is more than estimated hour, task can be completed in that duration
    let remainingHours = moment.duration(moment(`${receivedDate}T18:30:00`).diff(moment(`${receivedDate}T${receivedTime}`))).asHours();

    if (remainingHours >= estimatedHours) {
      setEndDate(time.add(estimatedHours, "hours").format())
    }
    else {
      // find how many more working hours are required other than the day on which task was assigned
      // : (estimatedHours - endOfFirstDay.diff(time.format(), 'hours'))
      // Number of additional days will be extraWork hours calculated above divided by 9 rounded off to an integer
      // Add the days to our answer
      // (additionalDays - 1) * 9 calculates the total number of working hours in the additional days needed to complete the task.
      // we subtract 1 because it is already accounted for the first day
      // Finally, we subtract the number of working hours that have already elapsed on the first day and the number of working hours
      // in the additional days needed to complete the task from the estimated duration of the task.
      // This gives us the number of working hours that need to be added to the end time.

      const endOfFirstDay = moment(time.format()).set('hour', 18).set('minute', 30)
      const additionalDays = Math.ceil((estimatedHours - endOfFirstDay.diff(time.format(), 'hours')) / 9)
      const endTime = moment(time.format()).add(additionalDays, 'days').set('hour', 9).set('minutes', 30);
      endTime.add(estimatedHours - moment.duration(endOfFirstDay.diff(time.format())).asHours() - (additionalDays - 1) * 9, 'hours')
      if (endTime.format("dddd") === 'Saturday') {
        endTime.add(2, 'days')
      }
      else if (endTime.format("dddd") === 'Sunday') {
        endTime.add(1, 'day')
      }
      setEndDate(endTime.format());
    }
  }
  useEffect(() => {
    endDateHandler(estimatedHours);
  }, [startTime, estimatedHours]);

  // Submit handler**********************************************************************
  const onSubmitHandler = (e) => {
    e.preventDefault();

    const queryParam = "?auth=" + localStorage.getItem("token");

    setIsLoading(true);
    axios
      .post(
        `https://project-tracker-7df16-default-rtdb.asia-southeast1.firebasedatabase.app/${bu}.json` +
        queryParam,
        {
          Division: division,
          "Received Date": `${receivedDate}T${receivedTime}:00`,
          "Start Date": `${startDate}T${startTime}:00`,
          "End Date": endDate,
          "Estimated Hours": estimatedHours,
          "Activity Type": activityType,
          "Number of VAs": va,
          "Static Slides": staticSlides,
          "Interactive Slides": interactiveSlides,
          "Adaption Slides": adaptionSlides,
          "Emailer Type": emailerType,
          "Number of Pages": emailerLandingPages,
          "Task Name": taskName,
          "Emailer Subject": emailerSubject,
          "Add Link": addLink,
          Priority: priority,
          Status: status,
          "Vistaar SPOC": user?.name,
          developerSlackId: developerSlackId,
          "Vistaar Developers": vistaarDevelopers,
          "BU SPOC": buSpoc,
          "BU SPOC Email": buSpocEmail,
          Comments: comments,
        }
      )
      .then(() => {
        toast.success("Task Added!");
        setIsLoading(false);

        // resetting input fields **************************************************
        setDivision("");
        setActivityType("");
        setVa("");
        setStaticSlides("");
        setInteractiveSlides("");
        setAdaptionSlides("");
        setCustomPages("");
        setTaskName("");
        setEmailerType("");
        setEmailerSubject("");
        setAddLink("");
        setPriority("Regular");
        setStatus("Not Initiated");
        setVistaarSpoc(user?.name);
        setvistaarDevelopers("");
        setDevelopersEmail("");
        setBuSpoc("");
        setComments("");

        // if task addition is successful, send message to slack***************************
        const webhookUrl =
          "https://hooks.slack.com/services/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";
        const data = {
          text: `*Task Details:* \n
            Hello <@${developerSlackId}> , \n
            *Activity Type:* ${activityType} \n
            *Business Unit:* ${bu.toUpperCase()} \n
            *Division:* ${division} \n
            *Task:* ${taskName} \n
            *ETA:* ${moment(endDate).format("DD-MM-yyyy,hh:mm:ss a")} \n
            ${va ? `*Number of VAs:* ${va}`.trim() : ``}
            ${emailerSubject ? `*Emailer Subject:* ${emailerSubject}\n` : ``}
            ${emailerSubject ? `*Links to add to emailer:* ${addLink}` : ``}
            \nFrom,\n ${vistaarSpoc}`,
        };
        axios
          .post(webhookUrl, JSON.stringify(data), {
            withCredentials: false,
            transformRequest: [
              (data, headers) => {
                delete headers.post["Content-Type"];
                return data;
              },
            ],
          })
          .then(() => {
            toast.success("Message Sent to Slack");

            // show a modal to ask user to continue adding task or head to Table view***
            setShowModal(true);
          })
          .catch((err) => {
            toast.error("Failed to send the message to slack");
          });
      })
      //If something happens and task is not added to firebase***********
      .catch((err) => {
        toast.error("Failed to add task");
        setIsLoading(false);
      });
  };

  return (
    <>
      <Navbar />

      {showModal && <AddTaskOrNot onClose={hideModalHandler} />}

      {showDevelopersModal && <DevelopersModal data={developers} show={showDevelopersModal} hide={hideDeveloperModalHandler} />}

      <div className="formContainer">
        <h1>Add New Task</h1>
        <form ref={form} className="form" onSubmit={onSubmitHandler}>
          <label htmlFor="bu">BU:</label>
          <select
            id="bu"
            name="bu"
            value={bu}
            required
            onChange={(e) => { buHandler(e.target.value) }}
          >
            <option value="" disabled hidden>
              Select an Option
            </option>
            <option value="apc">APC</option>
            <option value="asc">ASC</option>
            <option value="ail">AIL</option>
          </select>

          <label htmlFor="division">Division:</label>
          <select
            id="division"
            name="divisionName"
            onChange={divisionUpdate}
            disabled={!bu}
            required
            value={division}
          >
            <option value="">Select an Option</option>
            {divisionArray.map((div, index) => (
              <option value={div} key={index}>
                {div}
              </option>
            ))}
          </select>

          <label htmlFor="receivedDate">
            Received Date (From Client):
          </label>
          <div className="d-flex">
            <input
              required
              id="receivedDate"
              type="date"
              onChange={(e) => { setReceivedDate(e.target.value) }}
              value={receivedDate}
            />
            <input
              required
              type="time"
              value={receivedTime}
              onChange={(e) => { setReceivedTime(e.target.value) }}
            />
          </div>

          <label>Activity Type:</label>
          <select
            name="activityType"
            required
            value={activityType}
            onChange={activityTypeUpdate}
            disabled={!division}
          >
            <option value="">Select an Activity</option>
            <option>Edetailing</option>
            <option>Emailer</option>
            <option>Landing Page</option>
            <option>RMC</option>
          </select>

          <label htmlFor="">Priority:</label>
          <select
            defaultValue={"Regular"}
            required
            onChange={priorityUpdate}
            disabled={!division}
          >
            <option value="Regular">Regular</option>
            <option value="High">High</option>
          </select>

          <label htmlFor="">Status:</label>
          <select
            required
            value={status}
            onChange={statusUpdate}
            disabled={!division}
          >
            <option value="" disabled hidden>
              Select an Option
            </option>
            <option>Not Initiated</option>
            <option disabled={isInProgress}>In Progress</option>
            <option>On Hold</option>
            <option>Test Link Shared</option>
            <option>Uploaded to Sandbox</option>
            <option>Uploaded to Production</option>
            <option>Feedback In Progress</option>
            <option>Cancelled</option>
          </select>

          {nameSwitch && <label htmlFor="">{nameSwitch} Name:</label>}
          {nameSwitch && (
            <input
              type="text"
              name="taskName"
              value={taskName}
              required
              onChange={taskNameUpdate}
            />
          )}

          <label htmlFor="" hidden={emailerHide}>
            {nameSwitch} Subject:
          </label>
          <input
            type="text"
            value={emailerSubject}
            hidden={emailerHide}
            onChange={emailerSubjectUpdate}
            required={!emailerHide}
            name="emailerSub"
          />

          <label htmlFor="" hidden={vaHide}>
            Number of VAs:
          </label>
          <input
            type="number"
            min="0"
            onChange={vaUpdate}
            hidden={vaHide}
            value={va}
            name="numberOfVa"
          />

          <label htmlFor="" hidden={staticHide}>
            Static Slides:
          </label>
          <input
            type="number"
            min="0"
            onChange={staticSlidesUpdate}
            hidden={staticHide}
            value={staticSlides}
          />

          <label htmlFor="" hidden={interactiveHide}>
            Interactive Slides:
          </label>
          <input
            type="number"
            min="0"
            onChange={interactiveSlidesUpdate}
            hidden={interactiveHide}
            value={interactiveSlides}
          />

          <label htmlFor="" hidden={adaptionHide}>
            Adaption Slides:
          </label>
          <input
            type="number"
            value={adaptionSlides}
            min="0"
            onChange={adaptionSlidesUpdate}
            hidden={adaptionHide}
          />

          <label htmlFor="" hidden={emailerHide}>
            {nameSwitch} type:
          </label>
          <select
            hidden={emailerHide}
            value={emailerType}
            onChange={(e) => {
              setEmailerType(e.target.value);
            }}
          >
            <option value="">---Select---</option>
            <option>Custom</option>
            <option>Static</option>
          </select>

          <label htmlFor="emailerPages" hidden={emailerHide}>
            Emailer Pages:
          </label>
          <input
            value={emailerLandingPages}
            type="number"
            name='emailerPages'
            min="1"
            required
            hidden={emailerHide}
            onChange={(e) => { setEmailerLandingPages(e.target.value) }}
          />

          <label htmlFor="linkToAdd" hidden={emailerHide}>
            Links to add:
          </label>
          <input
            value={addLink}
            type="text"
            name="linkToAdd"
            hidden={emailerHide}
            onChange={addLinkUpdate}
          />

          <label htmlFor="startDate">
            Start Date:
          </label>
          <div className="d-flex">
            <input
              required
              id="startDate"
              type="date"
              onChange={(e) => { setStartDate(e.target.value) }}
              value={startDate}
            />
            <input
              required
              type="time"
              value={startTime}
              onChange={(e) => { setStartTime(e.target.value) }}
            />
          </div>

          <label htmlFor="estimatedTime">
            Estimated Time to Complete (in hours)
          </label>
          <input
            required
            value={estimatedHours}
            name="estimatedTime"
            type="number"
            min={1}
            onChange={(e) => { setEstimatedHours(e.target.value); }}
          />

          <label htmlFor="vistaarSpoc">Vistaar SPOC:</label>
          <div className="vistaarSpoc">{user?.name}</div>


          {/* Vistaar Spoc**************************************** */}
          <label htmlFor="vistaarSpocEmail">Vistaar SPOC's Email ID: </label>
          <input
            type="text"
            value={email}
            disabled={!division}
            id="vistaarSpocEmail"
            name="vistaarSpocEmail"
          />

          <label htmlFor="vistaarDevelopers">Developer's Name:</label>
          <div className="w-100 d-flex justify-content-between">
            <select
              disabled={!division}
              value={vistaarDevelopers}
              required
              onChange={vistaarDevelopersUpdate}
              id="vistaarDevelopers"
              name="vistaarDevelopers"
              className="w-75"
            >
              <option value="">---Select---</option>
              {developers.map(developer => (
                <option key={developer.id}>{developer.name}</option>
              ))}
            </select>

            <ProtectedContent>
              <div onClick={() => { setShowDevelopersModal(true) }}>
                <i className="bi bi-list-ul"></i>
              </div>
            </ProtectedContent>
          </div>

          <label htmlFor="developersEmail">Developer's Email ID: </label>
          <input
            disabled
            type="text"
            value={developersEmail}
            id="developersEmail"
            name="developersEmail"
          />

          <label htmlFor="">BU SPOC:</label>
          <input
            type="text"
            required
            value={buSpoc}
            onChange={buSpocUpdate}
            disabled={!division}
            name="buSpoc"
          />

          <label htmlFor="">BU SPOC Email:</label>
          <input
            type="text"
            required
            value={buSpocEmail}
            onChange={(e) => { setBuSpocEmail(e.target.value) }}
            disabled={!division}
            name="buSpocEmail"
          />

          <label htmlFor="comments">Comments:</label>
          <textarea
            value={comments}
            onChange={commentsUpdate}
            name="comments"
            disabled={!division}
          ></textarea>

          <button
            disabled={isLoading || !division}
            className="btnSubmit action-button"
            type="submit"
          >
            {!isLoading && "Add Task"}
            {isLoading && <Loading />}
          </button>
        </form>
      </div>
    </>
  );
}

export default AddTaskForm;