import React from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from '../UI/Modal'
import styles from './AddTaskOrNot.module.css'

const AddTaskOrNot = (props) => {

    const nav = useNavigate();
    const navigateHandler = e => {
        nav('/table-view')
    }

    return (
        <Modal onClose={props.onClose}>
            Do you want to add more tasks?
            <div className={styles.buttonDiv}>
                <button className={styles.closeButton} onClick={props.onClose}>Yes</button>
                <button onClick={navigateHandler}>No, take me to Table View</button>
            </div>
        </Modal>
    )
}

export default AddTaskOrNot