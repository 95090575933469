import axios from "axios";
import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const { createContext } = require("react");

export const UserContext = createContext({
    bu: "asc",
    isLoggedIn: false,
    token: "",
    uuid: "",
    error: false,
    isLoading: false,
    fetchUser: (token, id) => { }
});

export const UserContextProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [user, setUser] = useState('');
    const [email, setEmail] = useState('');
    const [token, setToken] = useState("");
    const [bu, setBu] = useState('asc');

    const nav = useNavigate();

    // bu handler ******************
    const buHandler = (buValue) => {
        setBu(buValue);
    }

    // login function*****************************
    const loginHandler = (email, password) => {
        setIsLoading(true);
        axios.post(`https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyAOeJT1HeBS7KtV-tN5N7FxMJV4K6gqcwo`, {
            email,
            password,
            returnSecureToken: true
        }).then((res) => {
            setError(false)
            localStorage.setItem('token', res.data.idToken);
            setToken(res.data.idToken)
            fetchUser(res.data.idToken, res.data.localId);
            toast.success('Login successful!')
            nav('/calendar', { replace: true })
        }).catch((err) => {
            setError(true)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    // logout handler******************************
    const logoutHandler = () => {
        localStorage.clear();
    }

    // fetch user++++++++++++++++++++++++++++++++++++++
    useEffect(() => {
        fetchUser();
    }, [token])
    const fetchUser = () => {
        const token = localStorage.getItem('token');
        if (token && jwtDecode(token).exp < Date.now()) {
            const { user_id: id, email } = jwtDecode(token);
            setEmail(email)
            return axios.get(`https://project-tracker-7df16-default-rtdb.asia-southeast1.firebasedatabase.app/users/${id}.json?auth=${token}`)
                .then((res) => {
                    setUser(res.data);
                }).catch((err) => {
                    toast.error(err)
                })
        }
    }

    return <UserContext.Provider
        value={{
            bu: bu,
            isLoading: isLoading,
            error: error,
            user: user,
            email: email,
            buHandler: buHandler,
            fetchUser: fetchUser,
            loginHandler: loginHandler,
            logoutHandler: logoutHandler
        }}>
        {children}
    </UserContext.Provider>
}