import "./TableView.css";
import Navbar from "../../navbar/Navbar";
import axios from "axios";
import { useState, useMemo, useEffect, useContext } from "react";
import { useTable, useSortBy, useFilters, useRowSelect, usePagination } from "react-table";
import { COLUMNS } from "./columns";
import LoadingSpinner from "../../UI/LoadingSpinner";
import EditTableData from "../../forms/task/EditTableData";
import * as XLSX from 'xlsx/xlsx.mjs';
import TaskHistory from "../../TaskHistory";
import DeleteModal from "../DeleteModal";
import { UserContext } from "../../../store/user-context";

function TableView() {

    //setting page title*********************************************************
    useEffect(() => {
        document.title = "Table View";
    }, []);

    const { bu, buHandler } = useContext(UserContext)

    const [taskData, setTaskData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [sortToggle, setSortToggle] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    //send this data to edit component
    const [editTaskData, setEditTaskData] = useState()

    //send this data to task history component
    const [historyTaskData, setHistoryTaskData] = useState()

    //state to edit row
    const [editableRowIndex, setEditableRowIndex] = useState();

    //modal state
    const [showModal, setShowModal] = useState(false);
    const [showHistoryModal, setShowHistoryModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    // state for sorting date 
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")

    const hideModalHandler = () => {
        setShowModal(false)
    }

    const hideHistoryModalHandler = () => {
        setShowHistoryModal(false)
    }

    const hideDeleteModalHandler = () => {
        setShowDeleteModal(false)
    }


    useEffect(() => {
        setIsLoading(true);
        const queryParam = '?auth=' + localStorage.getItem("token");

        //fetch data
        const fetchDataHandler = () => {
            axios.get(`https://project-tracker-7df16-default-rtdb.asia-southeast1.firebasedatabase.app/${bu}.json` + queryParam)
                .then(function (response) {
                    return response
                })
                .then(receivedData => {
                    const data = [];

                    // the data we receive as response is an object which has a unique id and 'server' data as key value pairs. So to extract data from those unique key, below is the code
                    for (const key in receivedData.data) {
                        const dataObj = {
                            id: key,
                            ...receivedData.data[key]
                        }
                        data.push(dataObj)
                    }
                    setTaskData(data);
                    setTableData(data);
                    setIsLoading(false)
                })
                .catch(function (error) {
                    // handle error
                    alert(error.message + " Please try again.");
                    setIsLoading(false)
                })
        }

        fetchDataHandler()
    }, [bu]);

    // to filter data as per the received date***********************************************
    useEffect(() => {
        if (startDate && !endDate) {
            setTableData(taskData.filter(x => x["Received Date"] >= startDate))
        }
        if (endDate && !startDate) {
            setTableData(taskData.filter(x => x["Received Date"] <= endDate))
        }
        if (!startDate && !endDate) {
            setTableData(taskData)
        }
        if (startDate && endDate) {
            setTableData(taskData.filter(x => x["Received Date"] >= startDate && x["Received Date"] <= endDate))
        }
    }, [startDate, endDate]);

    // export button functionality***************************
    const handleOnExport = () => {
        var wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(tableData);
        XLSX.utils.book_append_sheet(wb, ws, `${bu}`.toUpperCase());
        XLSX.writeFile(wb, `${bu}.xlsx`)
    };

    //Always memoize the data to speed-up the application when huge data is involved.
    //  COLUMNS is imported from the component columns.js
    const columns = useMemo(() => COLUMNS, []);
    // const data = useMemo(() => taskData, []);

    const {
        getTableProps,
        getTableBodyProps,
        // rows,
        page,
        headerGroups,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow,
    } = useTable({
        columns,
        data: tableData,
        editableRowIndex,
        setEditableRowIndex
    },
        useFilters,
        useSortBy,
        usePagination,
        useRowSelect,

        // to add Edit column **********************************************************
        (hooks) => {
            hooks.visibleColumns.push((columns => [
                ...columns,
                {
                    Header: "Action",
                    id: "actionButton",
                    Cell: ({ row }) => (
                        <div className='action-container'>
                            <button
                                className="action-button"
                                onClick={() => {
                                    //opens modal***************
                                    setShowModal(true);
                                    //sends data to EditTable.js****************
                                    setEditTaskData(row.values);
                                }}
                            >
                                Edit
                            </button>
                            <button className="action-button"
                                onClick={() => {
                                    //opens modal***************
                                    setShowDeleteModal(true);
                                    //sends data to EditTable.js****************
                                    setEditTaskData(row.values);
                                }}>
                                Delete
                            </button>
                        </div>
                    )
                },
                {
                    Header: "Task Log",
                    Cell: ({ row }) => (
                        <button
                            className="action-button"
                            onClick={() => {
                                //show task-history modal;
                                setShowHistoryModal(true)
                                setHistoryTaskData(row.values)
                            }}
                        >
                            View Log
                        </button>
                    )
                }
            ]))
        }
    )

    const { pageIndex, pageSize } = state;

    return (
        <>
            <div className="tableView">
                <Navbar />

                {/* action and controls******************************************************* */}
                <div className="row">

                    {/* Export button********************************* */}
                    <div className="btn export-btn col-12 col-md-4">
                        <button className="btn action-button" disabled={taskData.length === 0} onClick={handleOnExport}>Export</button>
                    </div>

                    {/* bu selector********************************************************** */}
                    <div className="bu-select col-12 col-md-4">
                        <div className="buSelector">
                            <select value={bu} name="bu" id="bu" onChange={(e) => { buHandler(e.target.value) }}>
                                <option value="asc">ASC</option>
                                <option value="ail">AIL</option>
                                <option value="apc">APC</option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="checkbox">Sort:
                                <input
                                    type="checkbox"
                                    id="vehicle1"
                                    name="vehicle1"
                                    value="Bike"
                                    onChange={() => setSortToggle((prev) => !prev)}
                                />
                            </label>
                        </div>
                    </div>

                    {/* Sort Date************************************************************* */}
                    {<div className="date-input col-12 col-md-4">
                        <label className="date-range">
                            Start Date:
                            <input disabled={isLoading || taskData.length === 0} className="date-range" type="date" value={startDate} onChange={(e) => { setStartDate(e.target.value) }} />
                        </label>

                        <label className="date-range">
                            End Date:
                            <input disabled={isLoading || taskData.length === 0} className="date-range" type="date" value={endDate} onChange={(e) => { setEndDate(e.target.value) }} />
                        </label>
                    </div>}
                </div>


                {/* Everything related to pagination ******************************************** */}
                {taskData.length && !isLoading && <div>
                    {/* Pages******************** */}
                    <span>
                        Page{' '}
                        {pageIndex + 1} of {pageOptions.length}
                        {' '}
                    </span>
                    <span>
                        | Go to page: {" "}
                        <input type="number" defaultValue={pageIndex + 1} onChange={e => {
                            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                            gotoPage(pageNumber)
                        }} style={{ width: '50px' }} />
                    </span>
                    <select className="page-entries" value={pageSize} onChange={(e) => { setPageSize(Number(e.target.value)) }} >
                        {
                            [10, 15, 20].map(pageSize => (
                                <option key={pageSize} value={pageSize}>Show {pageSize} entries</option>
                            ))
                        }
                    </select>

                    <button onClick={() => { gotoPage(0) }} disabled={!canPreviousPage}>{"<<"}</button>
                    {/* Pagination ******************************** */}
                    <span>
                        <button onClick={() => { previousPage() }} disabled={!canPreviousPage}>Previous</button>
                        <button onClick={() => { nextPage() }} disabled={!canNextPage}>Next</button>
                    </span>
                    <button onClick={() => { gotoPage(pageCount - 1) }} disabled={!canNextPage}>{">>"}</button>

                </div>}
                <div className="tableContainer">
                    {<div>
                        <table {...getTableProps()} className="table">
                            <thead>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th
                                                {...column.getHeaderProps(
                                                    sortToggle && column.getSortByToggleProps()
                                                )}
                                            >
                                                {column.render("Header")}{" "}
                                                <span>
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? "⬇"
                                                            : "⬆"
                                                        : ""}
                                                </span>
                                                <div>
                                                    {column.canFilter ? column.render("Filter") : null}
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan="0">
                                        {isLoading && <LoadingSpinner />}
                                    </td>
                                </tr>
                            </tbody>
                            {!isLoading && <tbody {...getTableBodyProps()}>
                                {taskData.length !== 0 && page.map((row) => {
                                    prepareRow(row);
                                    return (

                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => {
                                                return (
                                                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                                {taskData.length === 0 && <tr>
                                    <td>No tasks Found. Add some tasks.
                                    </td>
                                </tr>}
                            </tbody>}
                        </table>
                    </div>
                    }
                </div>
                {/* To show edit modal************************************************* */}
                {showModal && <EditTableData data={editTaskData} onClose={hideModalHandler} />}

                {/* to show task history modal******************************************* */}
                {showHistoryModal && <TaskHistory data={historyTaskData} onClose={hideHistoryModalHandler} />}

                {/* delete modal******************************************************* */}
                {showDeleteModal && <DeleteModal data={editTaskData} onClose={hideDeleteModalHandler} />}
            </div>
        </>
    );
}

export default TableView;