import React, { useEffect, useState } from 'react';
import Modal from './UI/Modal';
import styles from './TaskHistory.module.css';
import axios from 'axios';
import LoadingSpinner from './UI/LoadingSpinner';

const TaskHistory = (props) => {

  const [historyData, setHistoryData] = useState();
  const [isLoading, setIsLoading] = useState(false)

  //to get the history updates from server *****************************
  useEffect(() => {
    const getServerData = () => {
      setIsLoading(true);

      const queryParam = '?auth=' + localStorage.getItem("token")
      axios.get(`https://project-tracker-7df16-default-rtdb.asia-southeast1.firebasedatabase.app/history/${props.data.id}.json` + queryParam)
        .then((response) => {
          const data = [];
          // the data we receive as response is an object which has a unique id and 'server' data as key value pairs. So to extract data from those unique key, below is the code
          for (const key in response.data) {
            const dataObj = {
              id: key,
              ...response.data[key]
            }
            data.push(dataObj)
          }
          setHistoryData(data)
        })
      setIsLoading(false)
    }
    getServerData();
  }, []);


  return (
    <Modal onClose={props.onClose}>
      {isLoading && <LoadingSpinner />}
      <strong className={styles.title}>
        Task Log:
      </strong>

      {/* ***********************TABLE EDITION*************************************** */}
      <table className={styles.tableContainer}>
        <thead>
          <tr>
            <th>Status</th>
            <th>Date</th>
            <th>Developer</th>
          </tr>
        </thead>
        {isLoading && <tbody>
          <tr>
            <td>
              <LoadingSpinner />
            </td>
          </tr>
        </tbody>}
        {!isLoading && <tbody>
          {/* Mapping rest of the data********************* */}
          {historyData?.length == 0 && <tr>
            <td colSpan="3">
              No Previous data found
            </td>
          </tr>}
          {historyData?.map((data, index) => (
            <tr key={data["id"]}>
              <td>
                {historyData[index]["Status"]}
              </td>
              <td>
                {historyData[index]["Date"]}
              </td>
              <td>
                {historyData[index]["Developer"] || "NA"}
              </td>
            </tr>
          ))}
        </tbody>}
      </table>
    </Modal>
  )
}

export default TaskHistory