import React from "react";
import ReactDOM from "react-dom";
import styles from "./ViewAttachment.module.css";
import closebtn from "../UI/images/close-white.png";
import downloadBtn from '../UI/images/download-arrow-white.png'

const ViewAttachment = (props) => {


  //The blackscreen behind the modal
  const Backdrop = () => {
    return <div className={styles.backdrop}></div>;
  };

  const Attachment = () => {
    return (
      <div className={`${styles.attachment}`}>
        <embed
          // style={{ width: "auto", height: "100%" }}
          className={`${props.type === "application/pdf"
            ? `${styles.embedPdf}`
            : `${styles.embedImage}`
            }`}
          src={`${props.data}`}
        />
      </div >
    );
  };
  return (
    <>
      <img
        className={styles["close-btn"]}
        style={{ position: "fixed", top: "6%", left: "93%", zIndex: "12" }}
        onClick={props.onClose}
        width="40"
        height="40"
        src={closebtn}
      />

      {/* download button for image only************* */}
      {props.type !== "application/pdf" && <a download={`${props.name}`} href={`${props.data}`}>
        <img
          style={{ position: "fixed", top: "7%", left: "89%", zIndex: "12", width: "3%", height: "7%", cursor: "pointer" }}
          src={downloadBtn}
        />
      </a>}

      {ReactDOM.createPortal(
        <Backdrop />,
        document.getElementById("attachment")
      )}
      {ReactDOM.createPortal(
        <Attachment />,
        document.getElementById("attachment")
      )}
    </>
  );
};

export default ViewAttachment;
