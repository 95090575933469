export const ailDivision = [
    "Gennext",
    "GI Advance",
    "GI Maxima",
    "GI Prospera",
    "GI Prima",
    "IB",
    "Metabolics",
    "Neurolife",
    "Vaccine",
    "Women's Health",
    "Women's Health Fostera",
    "Women's Health Miliana",
];
export const ascDivision = [
    'CCD CORDIS',
    'CCD THROMBIS',
    'CNS Spectra',
    'CNS Victora',
    'Derma Magna',
    'Derma Prime',
    'Diabetes Janus',
    'Diabetes Maximus',
    'Diabetes Primus',
    'Hospicare',
    'Infinia',
    'Invicta',
    'Lifecare',
    'Onconext',
    'Unocare',
    'Xtensia'
];
export const apcDivision = [
    "Asha",
    "Endura",
    "General Medicine",
    "Multitherapy",
    "Novacare",
    "Orocare",
    "Osvita",
    "Restora",
];