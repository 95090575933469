import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Modal from '../../UI/Modal';
import styles from './EditExpenseForm.module.css'
import { toast } from 'react-toastify';

const EditExpenseForm = (props) => {

    // function to close the form using ESC button********************************
    useEffect(() => {
        const closeModal = (e) => {
            if (e.key === 'Escape') {
                props.onClose();
            }
        }
        window.addEventListener('keydown', closeModal);

        return () => window.removeEventListener('keydown', closeModal)
    }, []);

    const [isLoading, setIsLoading] = useState(false)
    const [expenseType, setExpenseType] = useState(props.data["Expense Type"]);
    const [invoiceDate, setInvoiceDate] = useState(props.data["Invoice Date"]);
    const [invoiceNumber, setInvoiceNumber] = useState(props.data["Invoice Number"]);
    const [inputName, setInputName] = useState(props.data["Payee"]);
    const [taxable, setTaxable] = useState(props.data["Taxable"]);
    const [igst, setIgst] = useState(props.data["IGST"]);
    const [cgst, setCgst] = useState(props.data["CGST"]);
    const [sgst, setSgst] = useState(props.data["SGST"]);
    const [invoiceValue, setInvoiceValue] = useState(props.data["Invoice Value"]);
    const [gstNumber, setGstNumber] = useState(props.data["GST Number"]);
    const [modeOfPayment, setModeOfPayment] = useState(props.data["Mode Of Payment"]);
    const [payor, setPayor] = useState(props.data["Payor"]);
    const [paymentDate, setPaymentDate] = useState("");
    const [reimburseDate, setReimburseDate] = useState(props.data["Reimburse Date"]);
    const [fileName, setFileName] = useState(props.data["File Name"]);
    const [fileData, setFileData] = useState(props.data["File Data"]);
    const [fileDataType, setFileDataType] = useState(props.data["File Data"]);
    const [status, setStatus] = useState(props.data["Status"]);
    const [showReimburse, setShowReimburse] = useState(false);

    //to calculate SGST, CGST and IGST***************************************************
    useEffect(() => {
        if (gstNumber && (Number(String(gstNumber).slice(0, 2)) === 27)) {
            setCgst(taxable * 0.09);
            setSgst(taxable * 0.09);
            setIgst(0);
            setInvoiceValue(+taxable + (2 * (+taxable) * 0.09))
        }
        else if (gstNumber && !(Number(String(gstNumber).slice(0, 2)) === 27)) {
            setSgst(0);
            setCgst(0)
            setIgst(+taxable * 0.18);
            setInvoiceValue(+taxable + (+taxable * 0.18));
        }
        else {
            setInvoiceValue(+taxable)
        }
    }, [taxable, gstNumber]);


    //updating status, reimbursement date and payment date as per payor***********
    useEffect(() => {
        if (payor === "Vistaar") {
            setShowReimburse(false);
            setStatus("Complete");
            setPaymentDate(invoiceDate);
            setReimburseDate("");
        } else {
            setShowReimburse(true);
            setStatus("Pending");
            setPaymentDate("");
        }
    }, [payor, invoiceDate]);

    //Upload file*****************
    const uploadFileHandler = e => {
        setFileName(e.target.files[0].name);
        setFileDataType(e.target.files[0].type);

        //Reading the file
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0])

        // reading the uploaded file. Converts the pdf/image file into base64 string.
        reader.onload = e => {
            setFileData(e.target.result);
        }
    }

    //to PUT the data to server
    const formSubmitHandler = (e) => {
        e.preventDefault();
        // setIsLoading(true)

        const data = {
            "Expense Type": expenseType,
            "Invoice Date": invoiceDate,
            "Invoice Number": invoiceNumber,
            "Payee": inputName,
            "Taxable": taxable,
            "CGST": cgst,
            "SGST": sgst,
            "IGST": igst,
            "Invoice Value": invoiceValue,
            "GST Number": gstNumber,
            "Mode Of Payment": modeOfPayment,
            "Payor": payor,
            "Payment Date": paymentDate,
            "Reimburse Date": reimburseDate,
            "File Data": fileData,
            "File Type": fileDataType,
            "File Name": fileName,
            "Status": status
        }

        const URL = `https://project-tracker-7df16-default-rtdb.asia-southeast1.firebasedatabase.app/expenses/${props.data.id}.json?auth=${localStorage.getItem("token")}`;

        axios.put(URL, data).then(() => {
            toast.success("Succesfully edited entry");
            setIsLoading(false);
            props.onClose();
            window.location.reload();
        }).catch(() => {
            setIsLoading(false);
            toast.error("Failed to update entry")
        });
    }

    return (
        <Modal onClose={props.onClose}>
            <strong>Edit Expenses</strong>
            <form className={styles.formElement} onSubmit={formSubmitHandler} >
                {/* Expense Type**************** */}
                <div>
                    <label>
                        Expense Type:
                    </label>
                    <select required value={expenseType} onChange={(e) => { setExpenseType(e.target.value) }}>
                        <option value="">---Select---</option>
                        <option>Amazon/Online</option>
                        <option>Asset</option>
                        <option>Cleaning</option>
                        <option>Electricity</option>
                        <option>Food</option>
                        <option>Fuel</option>
                        <option>Hosting</option>
                        <option>Internet</option>
                        <option>Misc</option>
                        <option>Mobile</option>
                        <option>QB</option>
                        <option>Stay</option>
                        <option>Tea</option>
                        <option>Travel</option>
                    </select>
                </div>

                {/* Invoice Date***************** */}
                <div>
                    <label>
                        Invoice Date:
                    </label>
                    <input required value={invoiceDate} type="date" onChange={(e) => (setInvoiceDate(e.target.value))} />
                </div>

                {/* Invoice Number***************** */}
                <div>
                    <label>
                        Invoice Number:
                    </label>
                    <input required value={invoiceNumber} type="text" onChange={(e) => (setInvoiceNumber(e.target.value))} />
                </div>

                {/* Name:***************** */}
                <div>
                    <label>
                        Payee:
                    </label>
                    <input required value={inputName} type="text" onChange={(e) => (setInputName(e.target.value))} />
                </div>

                {/* GST Number***************** */}
                <div>
                    <label>
                        GST Number:
                    </label>
                    <input value={gstNumber} type="text" minLength="15" maxlength="15" onChange={(e) => (setGstNumber(e.target.value))} />
                </div>

                {/* Taxable:***************** */}
                <div>
                    <label>
                        Taxable/Net Amount:
                    </label>
                    <input required value={taxable} type="number" min="0" onChange={(e) => (setTaxable(e.target.value))} />
                </div>

                {/* IGST:***************** */}
                {gstNumber && !(Number(String(gstNumber).slice(0, 2)) === 27) && <div>
                    <label>
                        IGST:
                    </label>
                    <input value={igst} type="number" min="0" readOnly />
                </div>}

                {/* SGST:***************** */}
                {gstNumber && (Number(String(gstNumber).slice(0, 2)) === 27) && <div>
                    <label>
                        SGST:
                    </label>
                    <input value={sgst} type="number" min="0" readOnly />
                </div>}

                {/* CGST:***************** */}
                {gstNumber && (Number(String(gstNumber).slice(0, 2)) === 27) && <div>
                    <label>
                        CGST:
                    </label>
                    <input value={cgst} type="number" min="0" readOnly />
                </div>}

                {/* Invoice Value:***************** */}
                <div>
                    <label>
                        Invoice Value:
                    </label>
                    <input value={invoiceValue} type="number" min="0" readOnly />
                </div>

                {/* Mode of Payment***************** */}
                <div>
                    <label>
                        Mode of Payment:
                    </label>
                    <select required value={modeOfPayment} onChange={(e) => { setModeOfPayment(e.target.value) }}>
                        <option>Bank Transfer</option>
                        <option>Cash</option>
                        <option>Credit Card</option>
                        <option>Expense Reimbursement</option>
                    </select>
                </div>

                {/* payee:***************** */}
                <div>
                    <label>
                        Payor:
                    </label>
                    <input required value={payor} type="text" onChange={(e) => (setPayor(e.target.value))} />
                </div>

                {/* Reimbursement Date:***************** */}
                {showReimburse && <div>
                    <label>
                        Reimbursement Date:
                    </label>
                    <input required value={reimburseDate} type="date" onChange={(e) => (setReimburseDate(e.target.value))} />
                </div>}

                {/* Attachment:***************** */}
                <div>
                    <label>
                        Attachment:
                    </label>
                    <input type="file" onChange={uploadFileHandler} />
                </div>

                {/* status:***************** */}
                <div>
                    <label>
                        Status:
                    </label>
                    <select value={status} onChange={(e) => { setStatus(e.target.value) }}>
                        <option>Pending</option>
                        <option>Complete</option>
                    </select>
                </div>

                <button className={styles.actionBtn}>
                    Edit
                </button>
                <button onClick={props.onClose}>
                    Cancel
                </button>
            </form>
        </Modal >
    )
}

export default EditExpenseForm