import { useContext } from "react"
import { UserContext } from "../../store/user-context"

const ProtectedContent = (props) => {
    const { user } = useContext(UserContext);
    if (user?.role === 'admin' || user?.role === 'superAdmin') {
        return props.children
    }
    return null
}

export default ProtectedContent