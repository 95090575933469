import React, { useContext } from 'react'
import AccessDenied from '../../components/AccessDenied';
import { UserContext } from '../../store/user-context';

const SuperAdminRoute = (props) => {
    const { user } = useContext(UserContext);
    if (user?.role === 'superAdmin') {
        return props.children
    }
    return <AccessDenied />
}

export default SuperAdminRoute