import { forwardRef, useContext, useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import Navbar from "../../components/navbar/Navbar";
import "./Calendar.css";
import axios from "axios";
import { UserContext } from "../../store/user-context";
import { toast } from "react-toastify";
import { colors } from '../../data/developerColors'
import * as bootstrap from 'bootstrap'

const Calendar = () => {
  //setting page title*********************************************************
  useEffect(() => {
    document.title = "Calendar";
  }, []);

  const { bu, buHandler } = useContext(UserContext);

  const [activityType, setActivityType] = useState("");
  const [fetchedObject, setFetchedObject] = useState();
  const [filteredObject, setFilteredObject] = useState();
  const [developers, setDevelopers] = useState([]);

  // get task data from server on intial load *************************
  useEffect(() => {
    const queryParam = "?auth=" + localStorage.getItem("token");
    axios
      .get(
        `https://project-tracker-7df16-default-rtdb.asia-southeast1.firebasedatabase.app/${bu}.json` +
        queryParam
      )
      .then((res) => {
        const data = [];

        // the data we receive as response is an object which has a unique id and 'server' data as key value pairs. So to extract data from those unique key, below is the code
        for (const key in res.data) {
          const dataObj = {
            ...res.data[key],
          };
          data.push(dataObj);
        }
        setFetchedObject(data);
        setFilteredObject(data);
      })
      .catch((err) => {
        toast.error('Failed to fetch data!')
      }
      );
  }, [bu]);

  // resetting activity type everytime bu changes**************************
  useEffect(() => {
    setActivityType("");
  }, [bu]);

  // to fill fetched objects based on activity type***********************************************
  const activityTypeHandler = (e) => {
    setActivityType(e.target.value);
  };

  // to apply activity type filter *******************************************************
  useEffect(() => {
    let timer = setTimeout(() => {
      if (activityType === "") {
        setFilteredObject(fetchedObject);
      } else if (activityType === "Edetailing") {
        setFilteredObject(
          fetchedObject.filter((x) => x["Activity Type"] == "Edetailing")
        );
      } else if (activityType === "Emailer") {
        setFilteredObject(
          fetchedObject.filter((x) => x["Activity Type"] == "Emailer")
        );
      } else if (activityType === "Landing Page") {
        setFilteredObject(
          fetchedObject.filter((x) => x["Activity Type"] == "Landing Page")
        );
      } else {
        setFilteredObject(
          fetchedObject.filter((x) => x["Activity Type"] == "RMC")
        );
      }
    }, 100);
    return () => {
      clearTimeout(timer);
    };
  }, [activityType]);

  // get developers name****************************************
  useEffect(() => {
    const developersData = () => {
      const token = localStorage.getItem('token')
      axios.get(`https://project-tracker-7df16-default-rtdb.asia-southeast1.firebasedatabase.app/developers.json?auth=${token}`)
        .then((res) => {
          let data = [];
          for (const key in res.data) {
            data.push({ id: key, ...res.data[key] })
          }
          setDevelopers(data.sort((a, b) => a.name.localeCompare(b.name)))
        }).catch((err) => {
          toast.error('Failed to get developers\' data')
        })
    }

    developersData();
  }, []);

  developers?.forEach((developer, index) => {
    return developer.color = colors[index]
  });

  //color as per developer
  const setColor = (developerName) => {
    let userColor = "#0096FF"
    if (developers && developers.length) {
      userColor = developers?.find(developer => developer.name === developerName)?.color
      return userColor
    }
    return userColor
  }

  // to populate calendar events *****************************************
  const calEvents = [];

  // if filteredObject is set, fill the calEvents array with the task details *********************
  filteredObject?.forEach((task) => {
    const tasknamedivision = task["Division"] + " - " + task["Task Name"];
    if (task["Status"] === "Not Initiated" || task["Status"] === "On Hold") {
      calEvents.push({
        title: tasknamedivision,
        extendedProps: {
          description: `${task["Division"]}-${task["Task Name"]}\nStatus: ${task["Status"]}`,
          vistaarSpoc: task["Vistaar SPOC"],
          developer: task["Vistaar Developers"]
        },
        start: task["Start Date"] || task["Received Date"],
        end: task["End Date"] || new Date(),
        color: setColor(task["Vistaar Developers"]),
        className: ['status', 'red']
      });
    }

    if (task["Status"] === "In Progress" || task["Status"] === "Feedback In Progress") {
      calEvents.push({
        title: tasknamedivision,
        extendedProps: {
          description: `${task["Division"]}-${task["Task Name"]}\nDeveloper: ${task["Vistaar Developers"]}\nStatus: ${task["Status"]}`,
          vistaarSpoc: task["Vistaar SPOC"],
          developer: task["Vistaar Developers"]
        },
        start: task["Start Date"] || task["Received Date"],
        end: task["End Date"] || new Date(),
        color: setColor(task["Vistaar Developers"]),
        className: ['status', 'yellow', `${task["Priority"] === 'High' ? 'highPriority' : null}`]
      });
    }

    if (task["Status"] === "Test Link Shared" || task["Status"] === "Uploaded to Sandbox") {
      calEvents.push({
        title: tasknamedivision,
        extendedProps: {
          description: `${task["Division"]} - ${task["Task Name"]}\nDeveloper: ${task["Vistaar Developers"]}\nStatus: ${task["Status"]}`,
          vistaarSpoc: task["Vistaar SPOC"],
          developer: task["Vistaar Developers"]
        },
        start: task["Start Date"] || task["Received Date"],
        end: task["End Date"] || new Date(),
        color: setColor(task["Vistaar Developers"]),
        className: ['status', 'green']
      });
    }
  });

  return (
    <div>
      <Navbar />
      <div className="action-control-calendar">
        {/* BU Selector************************************************************ */}
        <select
          className="buSelector-calendar"
          name="bu"
          id="bu"
          value={bu}
          onChange={(e) => { buHandler(e.target.value) }}
        >
          <option value="asc">ASC</option>
          <option value="ail">AIL</option>
          <option value="apc">APC</option>
        </select>
      </div>
      <div className="calendarContainer">
        <FullCalendar
          className="calendar"
          plugins={[dayGridPlugin, timeGridPlugin]}
          events={calEvents}
          initialView="dayGridMonth"
          headerToolbar={{ center: 'dayGridMonth,timeGridFiveDay' }}
          views={{
            timeGridFiveDay: {
              type: 'timeGrid',
              buttonText: 'Daily',
              duration: { days: 1 }
            }
          }}
          slotMinTime={"09:30:00"}
          slotMaxTime={"18:30:00"}
          titleFormat={{
            month: 'long',
            day: '2-digit',
            weekday: 'short'
          }}
          weekends={false}
          duration={{ days: 5 }}
          businessHours={[
            {
              start: '09:30',
              end: '18:30'
            }
          ]}
          eventDidMount={function (info) {
            return new bootstrap.Popover(info.el, {
              title: info.event.title,
              content: `<span>${info.event.extendedProps.description}</span>
              <br>
              <span>Developer: ${info.event.extendedProps.developer}</span>
              <br>
              <span>Assigned by: ${info.event.extendedProps.vistaarSpoc}</span>`,
              html: true,
              placement: 'auto',
              trigger: "hover",
            })
          }}
        />
      </div>

      {/* action and control********************************************************** */}
      <div className="action-control-calendar">
        {/* progress legend**************************************** */}
        {/* <div className="progress-legend">
          <div className="notInitiated">Not Initiated/On Hold</div>
          <div className="inProgress">In Progress/Feedback In Progress</div>
          <div className="testLinkShared">
            Test Link Shared/Uploaded to Sandbox
          </div>
        </div> */}

        {/* Activity Type*********************************************************** */}
        {/* <div className="activity-type">
            <label>Activity Type:</label>
            <select value={activityType} onChange={activityTypeHandler}>
              <option value="">---Select Activity---</option>
              <option>Edetailing</option>
              <option>Emailer</option>
              <option>Landing Page</option>
              <option>RMC</option>
            </select>
          </div> */}


      </div>
    </div >
  );
};

export default Calendar;