import { Routes, Route } from "react-router-dom";
import "./App.css";
import Login from "./components/auth/Login";
import AddTaskForm from "./components/forms/task/AddTaskForm";
import TableWithAView from "./components/Table/task/TableWithAView";
import ExpenseTableWithAView from './components/Table/expense/ExpenseTableWithAView'
import Calendar from "./components/Calendar/Calendar";
import ProtectedPage from "./routes/ProtectedPage";
import SuperAdminRoute from './routes/super-admin/SuperAdminRoute'
import { ailDivision, apcDivision, ascDivision } from "./data/divisionData";
import { UserContext, UserContextProvider } from "./store/user-context";
import { useContext, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'

function App() {
  const { fetchUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  //fetch user data
  useEffect(() => {
    setIsLoading(true);
    fetchUser()
    setIsLoading(false)
  }, []);

  return (
    <UserContextProvider>
      {!isLoading && <div className="App">
        <Routes>
          {/* This is the landing page******************** */}
          <Route path="/" element={<Login />} />

          {/* Calendar page****************************** */}
          <Route path='/' element={<ProtectedPage />} >
            <Route
              path="/calendar"
              element={
                <Calendar />
              }
            />

            {/* table-view page****************************** */}
            <Route
              path="/table-view"
              element={
                <TableWithAView />
              }
            />

            {/* add-task page****************************** */}
            <Route
              path="/add-task"
              element={
                <AddTaskForm
                  ailDivision={ailDivision}
                  apcDivision={apcDivision}
                  ascDivision={ascDivision}
                />
              }
            />

            {/* Expenses page****************************** */}
            {<Route
              path="/expenses"
              element={
                <SuperAdminRoute>
                  <ExpenseTableWithAView />
                </SuperAdminRoute>
              }
            />}
          </Route>
        </Routes>
      </div >}
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={true}
        closeButton
        closeOnClick
        pauseOnHover={false}
        theme="light"
      />
    </UserContextProvider>
  );
}

export default App;
