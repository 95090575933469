import axios from 'axios'
import React, { useContext, useState } from 'react'
import Modal from '../UI/Modal'
import { toast } from 'react-toastify'
import Loading from '../UI/Loading'
import { UserContext } from '../../store/user-context'

const DeleteModal = (props) => {

    const [isLoading, setIsLoading] = useState(false)
    const { bu } = useContext(UserContext);
    const deleteHandler = () => {
        setIsLoading(true)
        const queryParam = '?auth=' + localStorage.getItem("token");
        axios.delete(`https://project-tracker-7df16-default-rtdb.asia-southeast1.firebasedatabase.app/${bu}/${props.data.id}.json` + queryParam)
            .then((res) => {
                toast.success('Task Deleted!');
                window.location.reload();
            }).catch((err) => {
                toast.error('Failed to delete task!')
            }).finally(() => {
                setIsLoading(false)
            })
    }
    return <Modal onClose={props.onClose}>
        <div>
            <p>
                {`Are you sure you want to delete the entry ${props.data.id}?`}
            </p>
            <div>
                <button className='action-button' disabled={isLoading} onClick={deleteHandler}>{isLoading ? <Loading /> : "Yes"}</button>
                <button onClick={props.onClose}>No</button>
            </div>
        </div>
    </Modal >
}

export default DeleteModal