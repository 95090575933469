import jwtDecode from 'jwt-decode';
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedPage = (props) => {
    const token = localStorage.getItem("token");
    if (token && jwtDecode(token).exp < Date.now()) {
        return <Outlet />
    }
    return <Navigate to="/" />
}

export default ProtectedPage