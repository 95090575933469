import React, { useContext } from 'react'
import { UserContext } from '../../store/user-context';

const SuperAdminContent = (props) => {
    const { user } = useContext(UserContext);
    if (user?.role === 'superAdmin') {
        return props.children
    }
    return null
}

export default SuperAdminContent